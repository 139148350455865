import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import iconEyeHidden from '../../assets/icons/icon-textfield-eye.svg';
import iconEye from '../../assets/icons/icon-textfield-eye-hidden.svg';
import { Button, MenuItem, Select } from '@mui/material';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { ReactComponent as iconDropDown } from '../../assets/icons/icon-drop-down.svg';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAccount,
  selectBalance,
  selectBanners,
  selectFirebaseJWT,
  setAccount,
  setBalance,
} from '../../redux/features/user/userSlice';
import { getContacts, getTXlist, getUserBanner, getTXlast, getUserBalance } from '../../api';
import { toast } from 'react-toastify';
import transformNumbers from '../../utils/transformNumbers';
import Reward from '../../components/Reward/Reward';
import iconEmail from '../../assets/icons/icon_email.svg';
import Attention from '../../components/Attention/Attention';
import UserContext from '../../UserContext/UserContext';
import { v4 as uuidv4 } from 'uuid';
import useUserRole from '../../utils/useUserRole';
import DashboardAgent from '../../components/DashboardAgent';
import DashboardUser from '../../components/DashboardUser';

const Dashboard = () => {
  const dispatch = useDispatch();
  const { loading, setLoading } = useContext(UserContext);
  const [localLoading, setLocalLoading] = useState({
    contactsLoading: false,
    txLoading: false,
  });

  const attentionOutgoingTxData = useSelector(
    (state) => state.socket.outgoingTxData['Needs your attention']
  );
  const attentionIncomingTxData = useSelector(
    (state) => state.socket.incomingTxData['Needs your attention']
  );

  // check if verified email or not to show verify block
  const checkEmailField = (email) => {
    if (email !== '' && email?.length > 0 && email !== undefined) return email;
  };

  const { userBalance, userToken, userBanners, userProfile } = useSelector((state) => ({
    userBalance: selectBalance(state),
    userToken: selectFirebaseJWT(state),
    userBanners: selectBanners(state),
    userProfile: selectAccount(state),
  }));
  console.log(userProfile)

  const [user, setUser] = useState({
    userEmail: checkEmailField(userProfile?.personalInfo?.email),
    userEmailVerified: userProfile?.personalInfo?.emailVerified || false,
    userReferralCode: userProfile?.referralCode || '',
    userContacts: [],
    userTransactions: [],
    userBanner: {},
    canTransact: true,
  });

  const [wallet, setWallet] = useState({
    amountTotal: null,
    amountOfWalletInUsd: null,
    balance: [],
    visible: false,
  });

  const userRole = useUserRole();
  const isAgent = useUserRole('agent');

  const [filter, setFilter] = useState('oneWeek');
  const [receivedFilter, setReceivedFilter] = useState('oneWeek');
  const [totalSentData, setTotalSentData] = useState({});
  const [totalReceivedData, setTotalReceivedData] = useState({});

  // destruction obj for better jsx
  const { amountTotal, amountOfWalletInUsd, balance, visible } = wallet;
  const {
    userEmail,
    userEmailVerified,
    userContacts,
    userTransactions,
    userBanner,
    userReferralCode,
    canTransact,
  } = user;

  const handleChangeSend = (event) => setFilter(event.target.value);

  const handleChangeReceived = (event) => setReceivedFilter(event.target.value);

  // hide amounts of wallet on click on eye icon near wallet balance
  const toggleVisibleWallet = () => setWallet({ ...wallet, visible: !wallet.visible });

  // get amount of USD in wallet after updating balance and calculating total amount of all tokens
  const getAmountOfWalletInUsd = () =>
    transformNumbers(userBalance.find((item) => item.currency === 'USD')?.amount);

  // calculate total amount of all token in wallet only in USD
  const calculateTotalAmountInUSD = (data) => {
    if (data) {
      const exchangeRates = data?.reduce((acc, token) => {
        const { currency, rate } = token;
        acc[currency] = rate;
        return acc;
      }, {});

      // Use reduce to calculate the total amount in USD
      const totalAmountUSD = data?.reduce((total, item) => {
        // If the currency is not USD, convert the amount to USD using the exchange rate
   
        if (item.currency !== 'USD' ) {
          total += item.amount / exchangeRates[item.currency];
        } else{
          // If the currency is USD, no conversion needed
          total += item.amount;
        }
        return total;
      }, 0);

      // Round to 2 decimal places and add prettier format
      return transformNumbers(totalAmountUSD.toFixed(2)) || 0;
    }
  };

  const summationsData = async () => {
    try {
      const data = await getTXlist(userToken, 365);
      if (data.status === 200) {
        const { totalSent, totalReceived } = data?.data?.summations || {};
        if (totalSent) {
          const filteredTotalSent = Object.fromEntries(
            Object.entries(totalSent).map(([key, value]) => [key, value || 0])
          );
          setTotalSentData(filteredTotalSent);
        } else {
          console.error('Total sent data not found.');
        }

        if (totalReceived) {
          const filteredTotalReceived = Object.fromEntries(
            Object.entries(totalReceived).map(([key, value]) => [key, value || 0])
          );

          setTotalReceivedData(filteredTotalReceived);
        } else {
          console.error('Total received data not found.');
        }
      } else {
        console.error('Failed to fetch data.');
      }
    } catch (error) {
      console.log('getTXlist', error);
      toast.error(`Something went wrong...${error?.message || 'Oops!'}`);
    }
  };

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      fontSize: theme.typography.pxToRem(12),
      lineHeight: '150%',
    },
  }));

  useEffect(() => {
    summationsData();
  }, [userToken]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        setLocalLoading((loadings) => ({
          ...loadings,
          contactsLoading: true,
          txLoading: true,
        }));

        const balanceResponse = await getUserBalance(userToken);
        const dataArray = Object.entries(balanceResponse?.data).map(([currency, info]) => ({
          currency,
          ...info,
        }));

        balanceResponse?.data && dispatch(setBalance(dataArray));

        const banner = await getUserBanner(userToken);
        const transactions = await getTXlast(userToken);

        setUser((prevUser) => ({
          ...prevUser,
          userBanner: banner.data || {},
        }));

        dispatch(
          setAccount({
            ...userProfile,
            canTransact: transactions?.data?.canTransact,
          })
        );

        setUser((prevUser) => ({
          ...prevUser,
          userTransactions: Object.values(transactions?.data?.transactions)?.[0] || [],
          canTransact: transactions?.data?.canTransact,
        }));

        setLoading(false);
        setLocalLoading((loadings) => ({ ...loadings, txLoading: false }));

        const contacts = await getContacts(userToken, 1, 5, 'all');
        setUser((prevUser) => ({
          ...prevUser,
          userContacts: contacts?.data?.recentActivity || [],
        }));
        setLocalLoading((loadings) => ({ ...loadings, contactsLoading: false }));

        setUser((prevUser) => ({
          ...prevUser,
          userTransactions: Object.values(transactions.data.transactions)[0] || [],
        }));
        setLocalLoading((loadings) => ({ ...loadings, txLoading: false }));
      } catch (error) {
        console.error(error);
        toast.error(`Something went wrong...${error?.message || 'Oops!'}`);
        setLoading(false);
        setLocalLoading(false);
      }
    };

    fetchData();
  }, [userToken]);

  // if user balance is changed  - update total in the wallet
  useEffect(() => {
    if (userBalance) {
      console.log('heeee',calculateTotalAmountInUSD(userBalance))
      setWallet({
        amountTotal: calculateTotalAmountInUSD(userBalance),
        amountOfWalletInUsd: getAmountOfWalletInUsd(),
        balance: userBalance,
      });
    }
  }, [userBalance, userProfile]);
  
  function convertToTitleCase(str) {
      if (!str) {
          return ""
      }
      return str.toLowerCase().replace(/\b\w/g, s => s.toUpperCase());
    }

  return !loading && balance?.length > 0 ? (
    <>
<div style={{ 
  background: 'linear-gradient(135deg, #f8f9ff, #f0f3ff)',
  padding: '24px clamp(16px, 5vw, 32px)',
  borderRadius: '16px',
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.08)',
  width: '100%',
  maxWidth: '800px',
  margin: '0 auto 24px',
  transition: 'transform 0.2s ease',
  position: 'relative',
  overflow: 'hidden',
  border: '1px solid rgba(255, 255, 255, 0.3)'
}}>
  <h2 style={{
    fontFamily: 'Inter, system-ui, -apple-system, sans-serif',
    fontSize: 'clamp(1.5rem, 4vw, 1.75rem)',
    margin: 0,
    lineHeight: 1.3,
    color: '#1a1a1a',
    fontWeight: 600,
    letterSpacing: '-0.02em',
    position: 'relative',
    zIndex: 2
  }}>
    Welcome back, {`${convertToTitleCase(userProfile?.personalInfo?.title)}. ${convertToTitleCase(userProfile?.personalInfo?.firstName || '')} ${convertToTitleCase(userProfile?.personalInfo?.lastName || '')}`}!
  </h2>
  
  {/* Optional decorative elements */}
  <div style={{
    position: 'absolute',
    right: '-40px',
    top: '-40px',
    width: '120px',
    height: '120px',
    background: 'rgba(255, 255, 255, 0.15)',
    borderRadius: '50%',
    zIndex: 1
  }} />
</div>
      <div className='dashboard'>

        <div className='dashboard-wallet'> 
            
          <div className='wallet'>
            <div className='wallet-total'>
              <div className='el-text-s wallet-total'>
                TOTAL BALANCE
                <span className='wallet-total-icon' onClick={() => toggleVisibleWallet()}>
                  <img src={visible ? iconEye : iconEyeHidden} alt='' />
                </span>
              </div>
            </div>

            <div className='el-title-h4 wallet-ballance'>
              USD {visible ? '**.**' : transformNumbers(amountTotal, 2)}      
            </div>

            <div className='wallet-info'>
              <div className='wallet-info-box'>
                <div className='wallet-info-el'>
                  <div className='el-text-xs wallet-info-el-title'>Wallet Balance</div>
                  <div className='el-title-h6 med wallet-info-el-value'>
                    {/* USD {visible ? '**.**' :  parseFloat(amountOfWalletInUsd).toFixed(2)} */}
                    USD {visible ? '**.**' : transformNumbers(amountOfWalletInUsd, 2)}
                  </div>
                </div>

                {balance?.length > 0 &&
                  balance.map(({ currency, amount,rate }) => {
                    if (amount > 0 && currency === 'SSP') {
                      return (
                        <div className='wallet-info-el bottom' key={uuidv4()}>
                          <div className='el-text-xs wallet-info-el-title'>
                            <span className='wallet-info-el-text'>{currency} Balance</span>

                          </div>
                          <div className='el-title-h6 med wallet-info-el-value'>
                            {visible ? '**.**' : transformNumbers(amount, 2)}
                            <span className='el-text-s gray'>
                              USD {visible ? '**.**' : transformNumbers(amount / rate, 2) || '0.00'}
                            </span>
                          </div>
                        </div>
                      );
                    }
                  })}
              </div>
              <div className='wallet-info-box'>
                {balance?.length > 0 &&
                  balance.map(({ currency, amount, rate }) => {
                    if (currency === 'PESA') {
                      return (
                        <div className='wallet-info-el' key={uuidv4()}>
                          <div className='el-text-xs wallet-info-el-title'>{currency}</div>
                          <div className='el-title-h6 med wallet-info-el-value'>
                            {visible ? '**.**' : transformNumbers(amount, 2)}
                            <span className='el-text-s gray'>
                              USD {visible ? '**.**' : transformNumbers(amount / rate, 2) || '0.00'}
                            </span>
                          </div>
                        </div>
                      );
                    }
                  })}
              </div>
            </div>

            <div className='wallet-links'>
              <Button
                disabled={!canTransact}
                component={Link}
                to={`/${userRole}/send/system`}
                variant='outlined'
                className='el-button orange'
              >
                Send money
              </Button>

              {!isAgent && (
                <Button
                  component={Link}
                  to='/user/deposit/system'
                  variant='outlined'
                  className='el-button'
                >
                  Deposit
                </Button>
              )}
            </div>

            {!canTransact && (
              <Attention type='error'>
                You cannot send any more transactions without verifying your identity. You can
                contact us if you need our help with this.
              </Attention>
            )}
          </div>

          <div className='statistic'>
            <div className='statistic-el send'>
              <div className='el-text-s statistic-el-title'>
                <div className='title el-text-s'>
                  Sent <div className='title-slash'>/</div>
                </div>
                <div className='statistic-el-select'>
                  <Select
                    className='el-input-select'
                    value={filter}
                    onChange={handleChangeSend}
                    IconComponent={iconDropDown}
                    MenuProps={{
                      classes: {
                        paper: 'select-period',
                      },
                    }}
                  >
                    <MenuItem value='oneWeek'>week</MenuItem>
                    <MenuItem value='oneMonth'>month</MenuItem>
                    <MenuItem value='threeMonths'>3 months</MenuItem>
                    <MenuItem value='sixMonths'>6 months</MenuItem>
                    <MenuItem value='oneYear'>year</MenuItem>
                  </Select>
                </div>
              </div>

              <div className='el-title-h6 med statistic-el-value'>
                USD {visible ? '**.**' : totalSentData[filter]?.toFixed(2) || 0}
              </div>

              <div className='statistic-el-bottom'>
                <Link
                  to={isAgent ? '/agent/activity' : '/user/transactions'}
                  state={{
                    period: filter,
                    type: 'sent',
                  }}
                  className='el-link-xs semi statistic-el-link'
                >
                  view details
                </Link>
              </div>
            </div>

            <div className='statistic-el received'>
              <div className='el-text-s statistic-el-title'>
                <div className='title el-text-s'>
                  Received <div className='title-slash'>/</div>
                </div>
                <div className='statistic-el-select'>
                  <Select
                    className='el-input-select'
                    value={receivedFilter}
                    onChange={handleChangeReceived}
                    IconComponent={iconDropDown}
                    MenuProps={{
                      classes: {
                        paper: 'select-period',
                      },
                    }}
                  >
                    <MenuItem value='oneWeek'>week</MenuItem>
                    <MenuItem value='oneMonth'>month</MenuItem>
                    <MenuItem value='threeMonths'>3 months</MenuItem>
                    <MenuItem value='sixMonths'>6 months</MenuItem>
                    <MenuItem value='oneYear'>year</MenuItem>
                  </Select>
                </div>
              </div>

              <div className='el-title-h6 med statistic-el-value'>
                USD {visible ? '**.**' : totalReceivedData[receivedFilter]?.toFixed(2) || 0}
              </div>
              <div className='statistic-el-bottom'>
                <Link
                  to={isAgent ? '/agent/activity' : '/user/transactions'}
                  state={{
                    period: receivedFilter,
                    type: 'received',
                  }}
                  className='el-link-xs semi statistic-el-link'
                >
                  view details
                </Link>
              </div>
            </div>
          </div>
        </div>

        {isAgent ? (
          <DashboardAgent
            attentionOutgoingTxData={attentionOutgoingTxData}
            attentionIncomingTxData={attentionIncomingTxData}
            localLoading={localLoading}
          >
            {userBanners.rewards && (
              <Reward
                type={'rewards'}
                loading={loading.bannerLoading}
                data={userBanner}
                linkText={'Learn more'}
              />
            )}

            {userBanners.email && !userEmail && (
              <Reward
                type='email'
                data={{
                  image: iconEmail,
                  title: 'Complete the profile by adding an email address!',
                  text: 'It lets you get notified about transactions and service updates. Also, it makes your account more secure.',
                  info: '/auth/add-email',
                }}
                linkText={'Add email address'}
              />
            )}

            {userBanners.email && userEmail && !userEmailVerified && (
              <Reward
                type='email'
                data={{
                  image: iconEmail,
                  title:
                    'Verify your email address to get notified about transactions and updates!',
                  text: `We have sent you a verification link on your email`,
                  textEmail: `${userEmail}`,
                }}
              />
            )}
          </DashboardAgent>
        ) : (
          <DashboardUser
            isAgent={isAgent}
            account={userProfile}
            userBanners={userBanners}
            canTransact={canTransact}
            loading={loading}
            localLoading={localLoading}
            userBanner={userBanner}
            userContacts={userContacts}
            userTransactions={userTransactions}
            userReferralCode={userReferralCode}
            userEmail={userEmail}
            userEmailVerified={userEmailVerified}
          />
        )}
      </div>
    </>
  ) : (
    <span className='dashboard-loader'></span>
  );
};

export default Dashboard;
