import React, { useState, useEffect, useContext } from 'react';
import { getAgentOTP, getOTP, trustDeviceFunc } from '../../../api';
import { useSelector } from 'react-redux';
import {
  selectPhoneNumber,
  selectFirebaseJWT,
  selectWhatsAppCode,
} from '../../../redux/features/user/userSlice';
import { toast } from 'react-toastify';
import { useNavigate, useLocation } from 'react-router-dom';
import useUserRole from '../../../utils/useUserRole';


import whatsAppLogo from '../../../assets/img/whatsAppLogo.png';

export const WhatsAppTFA = () => {
  const { state } = useLocation();
  const userToken = useSelector(selectFirebaseJWT);
  const code = useSelector(selectWhatsAppCode);
   const phoneNumber = useSelector(selectPhoneNumber);
  const whatsappVerificationStatus = useSelector(
    (state) => state.socket.whatsAppCodeVerificationStatus
  );
  const userRole = useUserRole();

  const navigate = useNavigate();

  console.log('whats verification status from TFA modified', whatsappVerificationStatus);
  console.log('state from whatsAppTFA:', state);

//   useEffect(() => {
//     if (whatsappVerificationStatus) {
//       console.log('whatsapp verification is:', whatsappVerificationStatus);
//       if (state.buyFlow || state.flow || state?.linkBack) {
//         return navigate(state?.linkBack || `/${userRole}/dashboard`, { state: { ...state } });
//       } else {
//         return navigate(`/${userRole}/dashboard`);
//       }
//     }
//   }, [whatsappVerificationStatus]);

      const handleTrustDevice = async () => {
      try {
            // setLoading(true);
            await  trustDeviceFunc(userToken, code);
            console.log('whatsapp verification is:', whatsappVerificationStatus);
            if (state.buyFlow || state.flow || state?.linkBack) {
                  return navigate(state?.linkBack || `/${userRole}/dashboard`, { state: { ...state } });
            } else {
                  return navigate(`/${userRole}/dashboard`);
            }
      } catch (error) {
            console.log('handleTrustDevice', error);
            toast.error(`Something went wrong...${error?.message || 'Oops!'}`);
            // setLoading(false);
            // navigate('/auth/two-factor-auth-whatsapp', { state: { ...state, device: false } });
      }
      };

     useEffect(() => {
      const intervalId = setInterval(() => {
        if (whatsappVerificationStatus) {
          clearInterval(intervalId); 
          handleTrustDevice(); 
         
        }
      }, 2000); 
  
      return () => clearInterval(intervalId);
    }, [whatsappVerificationStatus]);

  return (
    <form
      style={{
        height: '100vh',
        marginTop: 'auto',
        marginBottom: 'auto',
      }}
    >
      <>
        <h4>
          Code: <span className='orange'>{code}</span>
        </h4>
        <span className='c-tfa-box-text el-text-m auth-content--title'>
          Send this code to our whatsapp number for verification
        </span>
      </>

      <span className='c-tfa-box-notes el-text-s'>
        <a
          aria-label='Chat on WhatsApp'
          href='https://wa.me/+18189183281'
          target='_blank'
          rel='noopener noreferrer'
        >
          <img alt='Chat on WhatsApp' src={whatsAppLogo} style={{ width: '50%' }} />
        </a>
      </span>

      <div id='recaptcha-container' className='resend'></div>
    </form>
  );
};
