import React, { useState } from 'react';
import UserContext from './UserContext';
import { auth } from '../firebase';
import { customSignupandLogin, getUserDevice } from '../api';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import { useSelector, useDispatch } from 'react-redux';

import {
  selectVerificationId,
  setPhoneNumber,
  setVerificationId,
  reset,
  selectFirebaseJWT,
  setFirebaseJWT,
  setJwtToken,
} from '../redux/features/user/userSlice';
import { resetRemittance } from '../redux/features/transaction/remittanceSlice';
import useAuthentication from '../components/Authentication/Authentication';
import { resetSockets } from '../redux/features/sockets/socketslice';

const UserProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const verificationId = useSelector(selectVerificationId);
  const firebaseJwt = useSelector(selectFirebaseJWT);
  const user = useAuthentication();
  const location = useLocation();

  const signUpandLogin = async (phone, signup, linkBack) => {
    try {
      setLoading(true);
      window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
        size: 'normal',
      });
      const appVerifier = window.recaptchaVerifier;
      const confirmationResult = await signInWithPhoneNumber(auth, phone, appVerifier);
      const id = confirmationResult.verificationId;
      // SMS sent. Prompt user to type the code from the message, then sign the
      // user in with confirmationResult.confirm(code).
      dispatch(setVerificationId(confirmationResult));
      //save phone
      dispatch(setPhoneNumber(phone));
      //navigate to the verify
      toast.success('Code sent');
      appVerifier.clear(confirmationResult);

      setTimeout(() => {
        setLoading(false);
        navigate('/auth/two-factor-auth', { state: { device: false, signup, linkBack } });
      }, 400);
    } catch (error) {
      console.log('signUpandLogin', error);
      const errorMsg = error.code;
      window.recaptchaVerifier.clear();

      switch (errorMsg) {
        case 'auth/operation-not-allowed':
          try {
            const code = await customSignupandLogin(phone);
            if (code === 200) {
              dispatch(setPhoneNumber(phone));
              toast.success('sign in successful');
              navigate('/auth/two-factor-auth', { state: { device: false, signup: signup } });
            }
          } catch (error) {
            console.log('signUpandLogin', error);
            toast.error('Please try again later');
          }
          break;
        case 'auth/too-many-requests':
          toast.error('Please try again in an hour');
          break;
        default:
          toast.error('Please try again later');
      }
      setLoading(false);
    }
  };

  const checkUserDevice = async (token = '', role) => {
    console.log(location.state?.linkBack);
    //check if user device is trusted
    const chooseJwt = token ? token : firebaseJwt;
    try {
      const deviceResponse = await getUserDevice(chooseJwt);
      // if device is trusted - refresh device token;
      // if (deviceResponse?.savedDevice) {
      //   // refresh device token
      //   dispatch(setJwtToken(deviceResponse.access_token));

        if (location.state?.linkBack) {
          navigate(location.state?.linkBack);
        } else {
          navigate(`/${role === 'agent' ? 'agent' : 'user'}/dashboard`);
        }
      // } else {
      //   navigate('/auth/trust-device', { state: { linkBack: location.state?.linkBack } });
      // }
    } catch (error) {
      console.log('checkUserDevice', error);
      toast.error('Failed to verify user');
    }
  };

  const resendOTP = async (phone) => {
    try {
      if (verificationId) {
        const appVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
          size: 'normal',
        });
        console.log('create');
        const confirmationResult = await signInWithPhoneNumber(auth, phone, appVerifier);
        dispatch(setVerificationId(confirmationResult));
        toast.success('OTP code resent successfull');
        appVerifier.clear(confirmationResult);
      } else {
        //use custom verification method
        const code = await customSignupandLogin(phone);
        if (code === 200) {
          toast.success('OTP code sent successfull');
        }
      }
    } catch (error) {
      console.log('resendOTP', error);
      toast.error('Resend failed');
    }
  };

  const refreshTokens = async () => {
    try {
      const userToken = await auth.currentUser.getIdToken(true);
      // const deviceToken = await getUserDevice(userToken);
      dispatch(setFirebaseJWT(userToken));
      // dispatch(setJwtToken(deviceToken.access_token));
    } catch (error) {
      console.log('refreshToken', error);
      toast.error(`Something went wrong...${error?.message || 'Oops!'}`);
    }
  };
  // refreshTokens();
  const logout = async () => {
    try {
      dispatch(reset());
      dispatch(resetRemittance());
      dispatch(resetSockets());
      await auth.signOut();
      navigate('/auth/login');
    } catch (error) {
      console.log('logout', error);
      toast.error('Failed to log out');
    }
  };

  const contextValue = {
    loading,
    setLoading,
    checkUserDevice,
    logout,
    signUpandLogin,
    resendOTP,
    refreshTokens,
    user,
  };

  return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>;
};

export default UserProvider;
