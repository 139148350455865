import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import TFA from '../../../components/TFA';
import UserContext from '../../../UserContext/UserContext';
import {
  setcustomJWT,
  checkUserAccount,
  setUserDevice,
  signUpUser,
  getOTP,
  getUserProfile,
} from '../../../api';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectPhoneNumber,
  selectVerificationId,
  selectFirebaseJWT,
  setFirebaseJWT,
  setJwtToken,
  selectJwtToken,
  selectAccount,
  setAccount,
  setAvatar,
} from '../../../redux/features/user/userSlice';
import { toast } from 'react-toastify';
import { auth } from '../../../firebase';
import useUserRole from '../../../utils/useUserRole';
import { initializeSocketConnection } from '../../../redux/socketEvents';

const TwoFactorAuth = () => {
  const { resendOTP, checkUserDevice, logout } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const userRole = useUserRole();
  const { state } = useLocation();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { phone, verificationId, firebaseJWT, jwtToken, inviteRFCode } = useSelector((state) => ({
    phone: selectPhoneNumber(state),
    verificationId: selectVerificationId(state),
    firebaseJWT: selectFirebaseJWT(state),
    jwtToken: selectJwtToken(state),
    inviteRFCode: selectAccount(state)?.inviteRFCode,
  }));

  // when user is at otp route
  const redirectOtp = () => {
    if (location.pathname === '/two-factor-auth') {
      //if user deivice is trusted
      if (jwtToken) return navigate('/user/dashboard');
    }

    // when user tries to navigate via url
    if (location.pathname === '/two-factor-auth') {
      //when trust device /when user is authenticated go to dashboard
      if (firebaseJWT && jwtToken) return navigate('/user/dashboard');
    }
  };

  const setUserData = async (token, data) => {
    const response = await getUserProfile(token);
    const { account, ...newObj } = response;
    dispatch(setAccount({ ...newObj, ...response?.account, ...{ accInfo: data } }));
    dispatch(setAvatar(response?.account?.avatar));
  };
  
  const userAccountCheck = async (token) => {
    try {
      const userData = await checkUserAccount(token);

      if (!userData.account) {
        if (state?.signup) {
          console.log(inviteRFCode);
          const signUpResponse = await signUpUser(token, inviteRFCode);

          if (signUpResponse.status === 200) {
            toast.success('Signup successful');
            await setUserData(token, userData);
            // navigate('/auth/trust-device', { state: { reg: true, linkBack: state?.linkBack } });
          } else {
            throw new Error(response.message);
          }
        } else {
          navigate('/auth/user-not-found', { state: { userData } });
        }
      } else {
        await setUserData(token, userData);
        await checkUserDevice(token, userData?.role);
      }
    } catch (error) {
      console.error('userAccountCheck error:', error);
      setLoading(false);
      toast.error(`Something went wrong...${error?.message || 'Oops!'}`);
      logout();
    }
  };

  const handleOtp = async (otp) => {
    try {
      setLoading(true);
      //if verificationID use firebase
      if (verificationId) {
        const confirmationResult = verificationId;
        //   const credential = firebase.auth.PhoneAuthProvider.credential(verificationId, otp);
        //   // Verify user
        //   await firebase.auth().signInWithCredential(credential);
        await confirmationResult.confirm(otp);
        // Get firebase jwt
        const idToken = await auth.currentUser.getIdToken(/* forceRefresh */ true);
        // Save token
        dispatch(setFirebaseJWT(idToken));

       
        // Check if user has account
        await userAccountCheck(idToken);
        dispatch(initializeSocketConnection());
      } else {
        const { customToken } = await setcustomJWT(phone, otp);
        if (customToken) {
          await auth().signInWithCustomToken(customToken);
          const jwtFirebase = await auth.currentUser.getIdToken(/* forceRefresh */ true);
          // Save token
          dispatch(setFirebaseJWT(jwtFirebase));

          //check if user has account for custom auth
          await userAccountCheck(jwtFirebase);
          dispatch(initializeSocketConnection());
        }
      }
      setLoading(false);
      // navigate to
    } catch (error) {
      setError(true);
      console.log('handleOtp', error);
      toast.error(`Something went wrong...${error?.message || 'Oops!'}`);
      setLoading(false);
    }
  };

  const handleOtpDevice = async (otp) => {
    try {
      setLoading(true);
      const data = await setUserDevice(otp, firebaseJWT);

      if (data.access_token) {
        dispatch(setJwtToken(data.access_token));

        if (!data.account && !state.flow && state.reg) {
          return navigate('/auth/thanks/registration', { state: { registration: true } });
        } else if (state.buyFlow || state.flow || state?.linkBack) {
          return navigate(state?.linkBack || `/${userRole}/dashboard`, { state: { ...state } });
        } else {
          return navigate(`/${userRole}/dashboard`);
        }
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      console.error('Error in handleOtpDevice:', error);
      setError(true);

      if (error.message === 'Access token not available') {
        toast.error('Failed to authenticate. Please try again.');
      } else {
        toast.error('An unexpected error occurred. Please try again.');
      }

      setLoading(false);
    }
  };

  const handleResendOTPDevice = async () => {
    try {
      await getOTP(firebaseJWT);
    } catch (error) {
      console.log('handleResendOTPDevice', error);
      toast.error('Failed to resend');
      setLoading(false);
    }
  };

  const handleResendOTP = () => resendOTP(phone);

  // redirect user when navigating
  useEffect(() => {
    redirectOtp();
  }, [location]);

  return (
    <TFA
      number={phone}
      handle={state?.device ? handleOtpDevice : handleOtp}
      resendFn={state?.device ? handleResendOTPDevice : handleResendOTP}
      trust={state?.device ? true : false}
      loading={loading}
      setError={setError}
      error={error}
    />
  );
};

export default TwoFactorAuth;
