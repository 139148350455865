import React, { useEffect, useState } from 'react';
import usePictures from '../../hooks/usePictures';
import { TextField, Button, List, ListItem, Select } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import PropTypes from 'prop-types';
import { ReactComponent as iconDropDown } from '../../assets/icons/icon-drop-down.svg';
import Attention from '../Attention/Attention';
import { NumericFormat } from 'react-number-format';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Estimate from '../Estimate';
import {
  selectAccount,
  selectBalance,
  selectFirebaseJWT,
  selectJwtToken,
  selectTransferData,
  setBalance,
  setTransferData,
} from '../../redux/features/user/userSlice';
import { ethers, JsonRpcProvider, formatEther, parseUnits } from 'ethers';
import transformNumbers from '../../utils/transformNumbers';
import closeFlow from '../../utils/closeFlow';
import { v4 as uuidv4 } from 'uuid';
import ShortenedAddress from '../ShortenedAddress';
import { selectRemitDetails } from '../../redux/features/transaction/remittanceSlice';
import Avatar from '../Avatar';
import useUserRole from '../../utils/useUserRole';
import { getUserBalance } from '../../api';
import useIsTablet from '../../hooks/useIsTablet';

const abi = [
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'txType',
        type: 'uint256',
      },
      {
        internalType: 'address',
        name: 'user',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'usdAmount',
        type: 'uint256',
      },
      {
        internalType: 'bool',
        name: 'registred_new_user',
        type: 'bool',
      },
      {
        internalType: 'address',
        name: '_feeCurrency',
        type: 'address',
      },
    ],
    name: 'calculateFee',
    outputs: [
      {
        internalType: 'uint256',
        name: 'Discount',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'totalFee',
        type: 'uint256',
      },
      {
        internalType: 'address',
        name: 'referer',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'Reward',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'refererReward',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'transfer_Reward',
        type: 'uint256',
      },
      {
        components: [
          {
            internalType: 'address',
            name: 'currencyAddress',
            type: 'address',
          },
          {
            internalType: 'uint256',
            name: 'rewards',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'fee',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'transferReward',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'refererReward',
            type: 'uint256',
          },
        ],
        internalType: 'struct pesabaseAgents.FeeInfo[]',
        name: '',
        type: 'tuple[]',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
];

const Provider = new JsonRpcProvider('https://bsc-dataseed.binance.org/', {
  name: 'binance',
  chainId: 56,
});

const contract = new ethers.Contract('0xc5caBD834Ea828ADA2020F5cB27331404358dda7', abi, Provider);

const Amount = ({ changeStep, transferData, setData, withdraw = false, remit = false }) => {
  const isTablet = useIsTablet();
  const icons = usePictures(
    require.context('../../assets/icons', false, /\.(png|jpe?g|svg|webp)$/)
  );

  const findCurrencyWithMaxAmountInUSD = (currencies) => {
    return currencies.reduce(
      (maxCurrency, currency) => {
        // Convert the amount to USD using the exchange rate
        const amountUSD = currency.amount / currency.rate;

        // Update maxCurrency if the current amountUSD is greater
        return amountUSD > maxCurrency.amountUSD ? { ...currency, amountUSD } : maxCurrency;
      },
      { amountUSD: 0 }
    );
  };

  const dispatch = useDispatch();
  const location = useLocation();
  const user = useSelector(selectAccount);
  const userToken = useSelector(selectFirebaseJWT);
  const remitDetails = useSelector(selectRemitDetails);
  const userBalance = useSelector(selectBalance);
  const userTransferData = useSelector(selectTransferData);
  const userDeviceToken = useSelector(selectJwtToken);
  const { userReview, userReceiver, paymentSystem } = transferData || userTransferData || {};
  const navigate = useNavigate();
  const allowedCurrencies = userBalance.filter(({ amount }) => amount > 0);
  const isTotalBalanceEmpty =
    userBalance.reduce((acc, { amount, rate }) => acc + amount * rate, 0) === 0;
  const currentCurrency =
    paymentSystem === 'pesabase'
      ? remit
        ? 'usd'
        : userReview?.currency ||
          findCurrencyWithMaxAmountInUSD(userBalance).currency?.toLowerCase() ||
          userBalance[0].currency?.toLowerCase()
      : withdraw
        ? 'pesa'
        : 'usd';

  const [inputValue, setInputValue] = useState(
    userReview?.sendValue || remitDetails?.sendValue || ''
  );
  const lastSegment = location.pathname.split('/').filter(Boolean).pop();
  const isRemitRoute = lastSegment === 'remit';
  const isMpesaRoute = lastSegment === 'mpesa';

  const txType = isRemitRoute ? '1' : isMpesaRoute ? '3' : '2';

  const [textAreaValue, setTextAreaValue] = useState(userReview?.comment || '');
  const [error, setBalanceError] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState(currentCurrency);
  const [selectedCurrencyTab, setSelectedCurrencyTab] = useState('pesa');
  const [disabled, setDisabled] = useState(true);
  const [transactionCoast, setTransactionCoast] = useState({
    inPesa: '',
    inUsd: '',
    inSsp: '',
    totalCoastInPesa: '',
    totalCoastInUsd: '',
    totalCoastInSsp: '',
    savedInPesa: '',
    transferRewardsInPesa: '',
  });
  const currencyPESA = userBalance.find((item) => item.currency?.toLowerCase() === 'pesa');
  const currencyUSD = userBalance.find((item) => item.currency?.toLowerCase() === 'usd');
  const currencySSP = userBalance.find((item) => item.currency?.toLowerCase() === 'ssp');
  const [isTextFieldFocused, setIsTextFieldFocused] = useState(false);
  const isAgent = useUserRole('agent');

  const getCurrentCurrency = () => {
    return userBalance.find((item) => item.currency?.toLowerCase() === selectedCurrency);
  };

  const findtotalFeeTupleArray = (tupleArray, targetAddress) => {
    for (let i = 0; i < tupleArray.length; i++) {
      if (tupleArray[i].currencyAddress === targetAddress) {
        if (tupleArray[i].fee) {
          return formatEther(tupleArray[i].fee);
        } else {
          console.error(`Sub-array at index ${i} does not have enough elements.`);
          return 0;
        }
      }
    }
    console.error(`Target address ${targetAddress} not found.`);
    return;
  };

  //     findrewardsInTupleArray
  const findrewardsInTupleArray = (tupleArray, targetAddress) => {
    for (let i = 0; i < tupleArray.length; i++) {
      if (tupleArray[i].currencyAddress === targetAddress) {
        if (tupleArray[i].transferReward) {
          return formatEther(tupleArray[i]?.transferReward);
        } else {
          console.error(`Sub-array at index ${i} does not have enough elements.`);
          return 0;
        }
      }
    }
    console.error(`Target address ${targetAddress} not found.`);
    return;
  };

  // check if enough pesa for fee in diff currency;
  const checkIfEnoghForFee = () => {
    return selectedCurrencyTab === 'pesa'
      ? currencyPESA.amount > transactionCoast.inPesa
      : currencyUSD.amount > transactionCoast.inUsd;
  };

  // check if Enough money at the current currency
  const checkBalance = () => {
    return !(parseFloat(selectTotalAmount().replace(/,/g, '')) > getCurrentCurrency().amount);
  };

  const calcTransactionCoast = async (value) => {
    if (value !== '' && !isNaN(value) && allowedCurrencies.length > 0) {
      let usdValue =
        `${(parseFloat(value) / parseFloat(getCurrentCurrency().rate)).toFixed(14)}` || '0';

      let address = isAgent
        ? '0x036A4E67f7b0a592102638FC37De614133B141a2'
        : '0x9e625Ef8112855008bEF143efe471976999c8d32';
      if (selectedCurrency === 'pesa') address = '0x4adc604A0261E3D340745533964FFf6bB130f3c3';
      let userAdress = user.address;
      let creatAccount = remitDetails?.sender?.createAccount || false;

      const feees = await contract.calculateFee(
        txType,
        userAdress,
        parseUnits(usdValue),
        creatAccount,
        address
      );
      // console.log(feees[6], creatAccount);
      const formattedFeeInfoArray = feees[6].map((feeInfo) => ({
        currencyAddress: feeInfo.currencyAddress,
        rewards: feeInfo.rewards.toString(),
        fee: feeInfo.fee.toString(),
        refferalReward: feeInfo.refererReward.toString(),
        transferReward: feeInfo.transferReward.toString(),
      }));

      const transferRewards = parseFloat(
        findrewardsInTupleArray(formattedFeeInfoArray, '0x9e625Ef8112855008bEF143efe471976999c8d32')
      );
      const transferRewardsUSD = isRemitRoute ? 5 / parseFloat(currencyPESA.rate) : transferRewards;

      const transferRewardsInPesa = (
        parseFloat(transferRewardsUSD) * parseFloat(currencyPESA.rate)
      ).toString();
      const createUserRewardsInPesa = formatEther(feees[4].toString());

      const discountedFeeUSD = parseFloat(
        findtotalFeeTupleArray(formattedFeeInfoArray, '0x9e625Ef8112855008bEF143efe471976999c8d32')
      );

      const discountedFeePesa =
        parseFloat(
          findtotalFeeTupleArray(
            formattedFeeInfoArray,
            '0x4adc604A0261E3D340745533964FFf6bB130f3c3'
          )
        ) * parseFloat(currencyPESA.rate);

      // console.log(discountedFeePesa, discountedFeeUSD, 'herefor');
      const currentFee = selectedCurrencyTab === 'pesa' ? discountedFeePesa : discountedFeeUSD;
      const totalInUSD = parseFloat(usdValue) + parseFloat(discountedFeeUSD);
      const totalInPesa = parseFloat(usdValue) * parseFloat(currencyPESA.rate) + currentFee;
      const totalInSSP = parseFloat(usdValue) * parseFloat(currencySSP.rate) + currentFee;

      setTransactionCoast(() => ({
        inPesa: transformNumbers(discountedFeePesa),
        totalCoastInPesa: transformNumbers(totalInPesa),
        inUsd: transformNumbers(discountedFeeUSD),
        totalCoastInUsd: transformNumbers(totalInUSD),
        totalCoastInSsp: transformNumbers(totalInSSP),
        savedInPesa: discountedFeeUSD - discountedFeePesa / currencyPESA.rate,
        transferRewardsInPesa: transferRewardsInPesa,
        createUserRewardsInPesa,
      }));
    }
  };

  const handleTypeChange = (event) => {
    setSelectedCurrencyTab(event.target.value);
    setSelectedCurrency(event.target.value);
  };

  const handleInputChange = (value) => {
    if (value !== '' && !isNaN(value)) {
      if (value.length <= 20) {
        setInputValue(value);
      }
    } else {
      setInputValue('');
    }
  };

  const handleTextAreaChange = (event) => {
    const value = event.target.value;
    setTextAreaValue(value);
  };

  const saveTransactionData = (toBuy = false) => {
    const flow = {
      state: {
        flow: true,
        system: paymentSystem,
        linkBack: location.pathname,
        jumpToNextStep: true,
      },
    };

    return (dispatch) => {
      dispatch(
        setTransferData({
          ...transferData,
          userReview: {
            paymentSystem: paymentSystem,
            sendValue: inputValue,
            currency: selectedCurrency,
            transactionFeeCurrency: selectedCurrencyTab,
            transactionFee: selectFee(),
            totalMixed: selectTotal(),
            total: selectTotalAmount(),
            equivalent: getEquivalent(),
            comment: textAreaValue,
            transferRewardsInPesa: transactionCoast.transferRewardsInPesa,
          },
        })
      );
      if (toBuy) {
        navigate('/user/deposit/buy-pesa', flow);
      }
      //  else {
      //   navigate('/auth/trust-device', flow);
      // }
    };
  };

  const selectTotal = () => {
    if (paymentSystem === 'mobile' || selectedCurrency !== selectedCurrencyTab) {
      return true;
    } else {
      return false;
    }
  };

  const selectTotalAmount = () => {
    switch (selectedCurrency) {
      case 'pesa':
        return transactionCoast.totalCoastInPesa;
      case 'usd':
        return transactionCoast.totalCoastInUsd;
      case 'ssp':
        return transactionCoast.totalCoastInSsp;
      default:
        return 0;
    }
  };

  const selectFee = () => {
    switch (selectedCurrencyTab) {
      case 'pesa':
        return transactionCoast.inPesa;
      case 'usd':
        return transactionCoast.inUsd;
      default:
        return 0;
    }
  };

  const getEquivalent = () => {
    if (selectedCurrencyTab === 'usd' && selectedCurrency === 'usd') {
      return false;
    } else {
      return transactionCoast.totalCoastInUsd;
    }
  };

  const setTransactionData = () => {
    setData({
      userReview: {
        paymentSystem: paymentSystem,
        sendValue: inputValue,
        currency: selectedCurrency,
        transactionFeeCurrency: selectedCurrencyTab,
        transactionFee: selectFee(),
        totalMixed: selectTotal(),
        total: selectTotalAmount(),
        equivalent: getEquivalent(),
        comment: textAreaValue,
        transferRewardsInPesa: transactionCoast.transferRewardsInPesa,
      },
    });
  };

  const submit = () => setTransactionData();

  // focus on input
  const handleTextFieldFocus = () => setIsTextFieldFocused(!isTextFieldFocused);

  useEffect(() => {
    if (checkIfEnoghForFee()) {
      setSelectedCurrencyTab('pesa');
    }
  }, [selectedCurrency]);

  useEffect(() => {
    (async () => {
      let balanceResponse = await getUserBalance(userToken);
      const dataArray = Object.entries(balanceResponse?.data).map(([currency, info]) => ({
        currency,
        ...info,
      }));

      balanceResponse?.data && dispatch(setBalance(dataArray));
    })();
  }, []);

  useEffect(() => {
    calcTransactionCoast(parseFloat(inputValue));
  }, [selectedCurrency, selectedCurrencyTab, inputValue]);

  useEffect(() => {
    console.log(transactionCoast);
    if (checkBalance() && !isTotalBalanceEmpty) {
      setBalanceError(false);
      setDisabled(false);
    } else {
      setBalanceError(true);
      setDisabled(true);
    }
  }, [transactionCoast]);

  return (
    <div className='amount-box'>
      <div className='contact'>
        <div className='contact-info'>
          <div
            className={`contact-info-img ${!userReceiver?.avatar || paymentSystem === 'm-pesa' ? 'empty' : ''}`}
          >
            {paymentSystem !== 'm-pesa' && !withdraw && (
              <Avatar data={location?.state?.contact || userReceiver} />
            )}

            {paymentSystem === 'm-pesa' && !withdraw && (
              <img className='phone' src={icons['icon-phone.svg']} alt='Phone icon' />
            )}

            {withdraw && (
              <img className='wallet' src={icons['icon_wallet.svg']} alt='Wallet icon' />
            )}
          </div>

          <div className='contact-info-details'>
            {userReceiver?.name?.trim() !== '' && (
              <span className='el-text-m med name'>{userReceiver?.name}</span>
            )}

            {(!userReceiver?.name || userReceiver?.name === ' ') && (
              <span className='el-text-m med name'>{userReceiver?.phoneNumber}</span>
            )}

            {withdraw && (
              <span className='el-text-m med name'>
                {ShortenedAddress({ address: userReceiver?.address })}
              </span>
            )}

            {userReceiver?.favourite && (
              <span className='name-status-icon'>
                <img src={icons['icon-star.svg']} alt='' />
              </span>
            )}
          </div>
        </div>

        <Button onClick={() => changeStep(-1)} className='el-button-icon'>
          <img src={icons['icon-edit-gray.svg']} alt='' />
        </Button>
      </div>

      <div className={`item ${error ? 'error' : ''}`}>
        <div className='item-wrap'>
          <div className='item-count'>
            <NumericFormat
              className='item-count-input'
              customInput={TextField}
              variant='outlined'
              placeholder='0.00'
              value={inputValue}
              onValueChange={(values) => {
                handleInputChange(values?.value);
              }}
              isnumericstring='true'
              allowNegative={false}
              decimalScale={2}
              thousandSeparator={','}
              fixedDecimalScale
              inputProps={{ maxLength: 20 }}
            />
          </div>

          {userBalance && !isTotalBalanceEmpty && (
            <div className='item-balance el-text-xs'>
              Your balance:{' '}
              <span className='item-balance-count el-text-xs med'>
                <span className='currency'>
                  {getCurrentCurrency().amount.toFixed(2)}{' '}
                  <span className='currency'>{selectedCurrency}</span>
                </span>
              </span>
            </div>
          )}

          {isTotalBalanceEmpty && (
            <div className='item-balance el-text-xs'>
              Your balance:
              <span className='item-balance-count el-text-xs med'>
                <span className='currency'>{' 0.00'}</span>
              </span>
            </div>
          )}
        </div>
      </div>

      {!withdraw && !remit && paymentSystem !== 'm-pesa' && !isTotalBalanceEmpty && (
        <div className='select'>
          <Select
            className='el-input-select'
            value={selectedCurrency}
            onChange={handleTypeChange}
            IconComponent={iconDropDown}
            MenuProps={{
              classes: {
                paper: 'select-currency',
              },
            }}
          >
            {allowedCurrencies.map(({ currency }) => {
              return (
                <MenuItem value={currency.toLowerCase()} key={uuidv4}>
                  <img
                    className='MuiSelect-icon'
                    src={icons[`currency-${currency.toLowerCase()}.svg`]}
                    alt=''
                  />
                  {currency}
                </MenuItem>
              );
            })}
          </Select>
        </div>
      )}

      {inputValue > 0 && inputValue !== '' && !error && (
        <div className='transaction'>
          <div className='transaction-cost'>
            <div className='transaction-cost-wrap'>
              <div className='transaction-cost-left'>
                <div className='transaction-cost-count'>
                  {selectedCurrencyTab === 'pesa' &&
                    `${transformNumbers(transactionCoast.inPesa, 3)} PESA`}
                  {selectedCurrencyTab === 'usd' &&
                    `${transformNumbers(transactionCoast.inUsd, 3)} USD`}
                </div>

                {!isTablet && (
                  <div className='transaction-cost-gray'>
                    {selectedCurrencyTab === 'pesa' &&
                      `${transformNumbers(transactionCoast.inPesa / currencyPESA.rate, 3)} USD`}
                    {selectedCurrencyTab === 'usd' &&
                      `${transformNumbers(transactionCoast.inUsd * currencyPESA.rate, 3)} PESA`}
                  </div>
                )}
                <div className='transaction-cost-label'>Transaction cost</div>
              </div>

              <div className='transaction-cost-switch'>
                <List>
                  <ListItem
                    className={`el-text-m ${selectedCurrencyTab === 'pesa' ? 'active' : ''}`}
                    onClick={() => setSelectedCurrencyTab('pesa')}
                  >
                    {isTablet && (
                      <img className='MuiSelect-icon' src={icons[`currency-pesa.svg`]} alt='' />
                    )}
                    PESA
                    {isTablet && (
                      <>
                        <div className='transaction-cost-count'>
                          {transformNumbers(transactionCoast.inPesa, 3)} PESA
                        </div>

                        <div className='transaction-cost-gray'>
                          {selectedCurrencyTab === 'pesa' &&
                            `${transformNumbers(transactionCoast.inPesa / currencyPESA.rate, 3)} USD`}
                          {selectedCurrencyTab === 'usd' &&
                            `${transformNumbers(transactionCoast.inUsd * currencyPESA.rate, 3)} PESA`}
                        </div>
                      </>
                    )}
                  </ListItem>

                  {!withdraw && (
                    <ListItem
                      className={`el-text-m ${selectedCurrencyTab === 'usd' ? 'active' : ''}`}
                      onClick={() => setSelectedCurrencyTab('usd')}
                    >
                      {isTablet && (
                        <img className='MuiSelect-icon' src={icons[`currency-usd.svg`]} alt='' />
                      )}
                      USD
                      {isTablet && (
                        <>
                          <div className='transaction-cost-count'>
                            {transformNumbers(transactionCoast.inUsd, 3)} USD
                          </div>

                          <div className='transaction-cost-gray'>
                            {selectedCurrencyTab === 'pesa' &&
                              `${transformNumbers(transactionCoast.inPesa / currencyPESA.rate, 3)} USD`}
                            {selectedCurrencyTab === 'usd' &&
                              `${transformNumbers(transactionCoast.inUsd * currencyPESA.rate, 3)} PESA`}
                          </div>
                        </>
                      )}
                    </ListItem>
                  )}
                </List>
              </div>
            </div>
          </div>

          <div className='transaction-total'>
            {selectedCurrency === selectedCurrencyTab && (
              <div className='transaction-total-wrap'>
                <div className='transaction-total-count'>
                  <span className='transaction-total-item'>
                    <b>
                      {selectedCurrencyTab === 'pesa' &&
                        `${transformNumbers(transactionCoast.totalCoastInPesa, 3)} PESA`}
                      {selectedCurrencyTab === 'usd' &&
                        `${transformNumbers(transactionCoast.totalCoastInUsd, 3)} USD`}
                    </b>
                  </span>

                  {!isTablet && (
                    <span className='transaction-total-gray'>
                      {selectedCurrencyTab === 'pesa' &&
                        `${transformNumbers(
                          transactionCoast.totalCoastInPesa / currencyPESA.rate,
                          3
                        )} USD`}
                      {selectedCurrencyTab === 'usd' &&
                        `${transformNumbers(transactionCoast.totalCoastInPesa, 3)} PESA`}
                    </span>
                  )}
                </div>
                <div className='transaction-total-label'>Total</div>
              </div>
            )}

            {selectedCurrency !== selectedCurrencyTab && (
              <>
                <div className='transaction-total-wrap'>
                  <div className='transaction-total-count'>
                    <span className='transaction-total-equivalent'>
                      <span>{getEquivalent()} USD</span>
                    </span>
                  </div>

                  <div className='transaction-total-equivalent'>USD Equivalent</div>
                </div>

                <div className='transaction-total-wrap'>
                  <div className='transaction-total-count'>
                    <span className='transaction-total-item'>
                      <b>
                        {selectedCurrencyTab === 'usd' &&
                          selectedCurrency !== 'ssp' &&
                          `${inputValue} PESA`}
                        {selectedCurrencyTab === 'pesa' &&
                          selectedCurrency !== 'ssp' &&
                          `${inputValue} USD`}
                        {selectedCurrency === 'ssp' && `${inputValue} SSP`}
                      </b>
                    </span>

                    {!isTablet ? (
                      <span className='transaction-total-gray'>
                        {selectedCurrencyTab === 'pesa' &&
                          selectedCurrency !== 'ssp' &&
                          `${(inputValue * currencyPESA.rate).toFixed(3)} PESA`}
                        {selectedCurrencyTab === 'usd' &&
                          selectedCurrency !== 'ssp' &&
                          `${(inputValue / currencyPESA.rate).toFixed(3)} USD`}
                        {selectedCurrencyTab === 'pesa' &&
                          selectedCurrency === 'ssp' &&
                          `${(inputValue / currencySSP.rate).toFixed(3)} USD`}
                        {selectedCurrencyTab === 'usd' &&
                          selectedCurrency === 'ssp' &&
                          `${(inputValue / currencySSP.rate).toFixed(3)} USD`}
                      </span>
                    ) : (
                      <>&nbsp;</>
                    )}

                    <span className='transaction-total-item'>
                      <b>
                        {selectedCurrencyTab === 'pesa' &&
                          selectedCurrency !== 'ssp' &&
                          ` + ${transformNumbers(transactionCoast.inPesa, 3)} PESA`}
                        {selectedCurrencyTab === 'usd' &&
                          selectedCurrency !== 'ssp' &&
                          ` + ${transformNumbers(transactionCoast.inUsd, 3)} USD`}
                        {selectedCurrency === 'ssp' &&
                          selectedCurrencyTab === 'usd' &&
                          ` + ${transformNumbers(transactionCoast.inUsd, 3)} USD`}
                        {selectedCurrency === 'ssp' &&
                          selectedCurrencyTab === 'pesa' &&
                          ` + ${transformNumbers(transactionCoast.inPesa, 3)} PESA`}
                      </b>
                    </span>

                    {!isTablet && (
                      <span className='transaction-total-gray'>
                        {selectedCurrencyTab === 'pesa' &&
                          ` ${transformNumbers(transactionCoast.inPesa / currencyPESA.rate, 3)} USD`}
                        {selectedCurrencyTab === 'usd' && (
                          <>
                            {selectedCurrency === 'ssp'
                              ? `${transformNumbers(
                                  transactionCoast.inUsd * currencySSP.rate,
                                  3
                                )} SSP`
                              : `${transformNumbers(
                                  transactionCoast.inUsd * currencyPESA.rate,
                                  3
                                )} PESA`}
                          </>
                        )}
                      </span>
                    )}
                  </div>

                  <div className='transaction-total-label'>Total</div>
                </div>
              </>
            )}
          </div>

          {selectedCurrencyTab === 'usd' && (
            <Attention type='info'>
              You could save up to {parseFloat(transactionCoast.savedInPesa).toFixed(3)} USD paying
              a fee in PESA.
            </Attention>
          )}
        </div>
      )}

      {!isAgent &&
        !isTotalBalanceEmpty &&
        !checkIfEnoghForFee() &&
        selectedCurrencyTab === 'pesa' &&
        !error && (
          <Attention type='error'>
            You PESA balance is {transformNumbers(userBalance[0]?.amount)}.
            <span className='link' onClick={() => dispatch(saveTransactionData(true))}>
              Buy more tokens for cheaper transactions.
            </span>
          </Attention>
        )}

      {error && (
        <Attention type='error'>
          {isTotalBalanceEmpty
            ? 'The balance is empty. Please top up your account.'
            : 'The amount entered exceeds your available balance'}
        </Attention>
      )}

      {transactionCoast.transferRewardsInPesa > 0 && !withdraw && !error && (
        <Estimate
          count={parseFloat(transactionCoast.transferRewardsInPesa).toFixed(6)}
          text={'Your estimated reward'}
        />
      )}

      <div className='comment'>
        <div className='comment-text'>
          <TextField
            label='Comment (optional)'
            variant='outlined'
            fullWidth
            multiline
            minRows={1}
            maxRows={2}
            value={textAreaValue}
            onChange={handleTextAreaChange}
            onFocus={handleTextFieldFocus}
            onBlur={handleTextFieldFocus}
            inputProps={{ maxLength: 100 }}
          />
          {isTextFieldFocused && (
            <span className='comment-counter'>
              <span className='comment-counter-value'>{textAreaValue.length}</span>/100
            </span>
          )}
        </div>
      </div>

      <div className='send-btns'>
        <Button
          disabled={disabled || !(inputValue > 0) || !checkIfEnoghForFee()}
          onClick={() => {
            // if (userDeviceToken) {
              submit();
            // } else {
            //   dispatch(saveTransactionData());
            // }
          }}
          className='el-button orange'
          variant='contained'
        >
          Continue
        </Button>

        <div className='send-btns-link'>
          <span className='el-link-m el-button-link' variant='contained' onClick={closeFlow}>
            Cancel
          </span>
        </div>
      </div>
    </div>
  );
};

Amount.propTypes = {
  changeStep: PropTypes.func.isRequired,
  transferData: PropTypes.object.isRequired,
  setData: PropTypes.func.isRequired,
};

export default Amount;
