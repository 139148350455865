import React, { useEffect, useState } from 'react';
import RemitLocation from '../../components/AgentRemit/RemitLocation';
import RemmitUser from '../../components/AgentRemit/RemitUser';
import RemitAmount from '../../components/AgentRemit/RemitAmount';
import RemitReview from '../../components/AgentRemit/RemitReview';
import RemitSend from '../../components/AgentRemit/RemitSend';
import Steps from '../../../components/Steps';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { selectRemitDetails } from '../../../redux/features/transaction/remittanceSlice';
import { selectJwtToken } from '../../../redux/features/user/userSlice';

const AgentRemitFlow = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userDeviceToken = useSelector(selectJwtToken);
  const newUserFlowActivated = useSelector(selectRemitDetails)?.newUserFlow;

  const [steps, setSteps] = useState({
    current: 0,
    total: [
      { name: 'Location' },
      { name: 'Sender' },
      { name: 'Receiver' },
      { name: 'Amount' },
      { name: 'Review' },
      { name: 'Send' },
    ],
    moveToReview: false,
    newUserFlow: false,
    senderSteps: {
      current: 0,
    },
    receiverSteps: {
      current: 0,
    },
    newUserSteps: {
      current: 0,
    },
    sendSteps: {
      current: 0,
    },
  });

  const { current, senderSteps, receiverSteps, newUserSteps, newUserFlow, sendSteps } = steps;

  /**
   * Changes the main step of the Agent Remit Flow.
   *
   * @param {number} step - The step to change to. Use -1 to navigate to the previous step.
   */
  const changeMainStep = (step, back = false) => {
    setSteps((prevSteps) => {
      const newSteps = { ...prevSteps, current: prevSteps.current + step };

      if (back) {
        const key = prevSteps.current === 1 ? 'senderSteps' : 'receiverSteps';
        newSteps[key] = { ...prevSteps[key], current: 0 };
        newSteps.moveToReview = true;
      } else if (prevSteps.moveToReview) {
        newSteps.current = 4;
        newSteps.moveToReview = false;
      } else if (prevSteps.current === 0 && step === -1) {
        navigate(-1);
        return prevSteps;
      }

      return newSteps;
    });
  };

  /**
   * Changes the sub-step of the agent remit flow.
   *
   * @param {string} stepKey - The key of the step to be changed.
   * @param {number} stepChange - The amount by which the step should be changed.
   */
  const changeSubStep = (stepKey, stepChange) => {
    if (stepChange < 0) {
      if (stepKey === 'senderSteps') {
        if (senderSteps.current === 0) {
          changeMainStep(-1);
        } else if (newUserFlowActivated) {
          setSteps((prev) => {
            return {
              ...prev,
              newUserFlow: true,
            };
          });
        } else {
          setSteps((prev) => {
            return { ...prev, senderSteps: { current: prev.senderSteps.current + stepChange } };
          });
        }
      }

      if (stepKey === 'receiverSteps') {
        if (receiverSteps.current === 0) {
          if (newUserFlowActivated) {
            setSteps((prev) => {
              return {
                ...prev,
                current: 1,
                newUserFlow: true,
                newUserSteps: { current: 5 },
              };
            });
          } else {
            setSteps((prev) => {
              return { ...prev, current: 1 };
            });
          }
        } else if (newUserFlowActivated && receiverSteps.current !== 3) {
          setSteps((prev) => {
            return {
              ...prev,
              newUserFlow: true,
              newUserSteps: { current: 2 },
              receiverSteps: { current: 0 },
            };
          });
        } else {
          setSteps((prev) => {
            return { ...prev, receiverSteps: { current: prev.receiverSteps.current + stepChange } };
          });
        }
      }

      if (stepKey === 'newUserSteps') {
        if (newUserFlowActivated) {
          if (newUserSteps.current === 0) {
            setSteps((prev) => {
              return { ...prev, newUserFlow: false };
            });
          } else {
            setSteps((prev) => {
              return { ...prev, newUserSteps: { current: prev.newUserSteps.current + stepChange } };
            });
          }
        } else {
          setSteps((prev) => {
            return { ...prev, newUserSteps: { current: prev.newUserSteps.current + stepChange } };
          });
        }
      }

      if (stepKey === 'sendSteps') {
        setSteps((prev) => {
          return { ...prev, sendSteps: { current: prev.sendSteps.current + stepChange } };
        });
      }
    } else {
      console.log('go next');
      setSteps((prev) => {
        const newCurrent = prev[stepKey].current + stepChange;
        return {
          ...prev,
          [stepKey]: { current: newCurrent },
        };
      });
    }
  };

  /**
   * Changes the new user flow value.
   *
   * @param {any} boolean - The new value for the new user flow.
   */
  const changeNewUserFlow = (value) => {
    setSteps((prev) => {
      return { ...prev, newUserFlow: value };
    });
  };

//   useEffect(() => {
//     if (!userDeviceToken) {
//       const flow = { state: { flow: true, linkBack: location.pathname } };
//       navigate('/auth/trust-device', flow);
//     }
//   }, [userDeviceToken]);

  useEffect(() => {
    if (!newUserFlow) {
      setSteps((prev) => {
        return { ...prev, newUserSteps: { current: 0 } };
      });
    }
  }, [newUserFlow]);

  return (
    (
      <div className='send'>
        <Steps steps={steps} className='steps-agent' />

        {current === 0 && <RemitLocation changeStep={changeMainStep} />}

        {current === 1 && (
          <RemmitUser
            step={senderSteps.current}
            newUserSteps={newUserSteps.current}
            newUserFlow={newUserFlow}
            changeNewUserFlow={changeNewUserFlow}
            changeStep={changeSubStep}
            changeMainStep={changeMainStep}
            senderUser={true}
          />
        )}

        {current === 2 && (
          <RemmitUser
            step={receiverSteps.current}
            newUserSteps={newUserSteps.current}
            newUserFlow={newUserFlow}
            changeNewUserFlow={changeNewUserFlow}
            changeStep={changeSubStep}
            changeMainStep={changeMainStep}
            senderUser={false}
          />
        )}

        {current === 3 && (
          <RemitAmount changeMainStep={changeMainStep} changeStep={changeSubStep} />
        )}

        {current === 4 && <RemitReview changeMainStep={changeMainStep} />}

        {current === 5 && (
          <RemitSend
            steps={sendSteps.current}
            changeMainStep={changeMainStep}
            changeStep={changeSubStep}
          />
        )}
      </div>
    )
  );
};

export default AgentRemitFlow;
