import React, { useState } from 'react';
import useBodyClass from '../../hooks/useBodyClass';
import AccordionFaq from '../../components/AccordionFaq/AccordionFaq';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { ReactComponent as MyIndicatorIcon } from './../../assets/icons/icon-left-arrow.svg';
import { Container } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import Typography from '@mui/material/Typography';

const faqs = [
      {
            category: 'why Pesabase',
            faqList: [
                  {
                        title: 'Why do I need to register with Pesabase?',
                        content: 'There are several reasons you need to register with Pesabase to send money. <ul style="list-style-type: disc; padding-left: 20px;"><li><strong>Send and Receive Money</strong></li> The primary function of Pesabase is to facilitate international money transfers, so you need an account to initiate payments to other users or receive funds from them.<li><strong>Security and Compliance</strong></li>Registration involves identity verification to ensure security against fraud and comply with Know Your Customer (KYC) regulations. Pesabase is a fully regulated company, so compliance is essential for your security. Note that you can perform one send transaction before loading the KYC information.<li><strong>Account Management</strong></li>Once registered, you can manage your account details and transaction history and access customer support. <li><strong>Access to feature</strong></li>Depending on your location and verification level, you might gain access to additional features like mobile wallet functionality or higher transaction limits.</ul>',
                  },
                  {
                        title: 'How do I register with Pesabase?',
                        content: 'It’s very easy. All you need to do is click on the ‘Sign Up” button on the top right of pesabase.com and follow instructions. It will take you less than 3 minutes!',
                  },
                  {
                        title: 'What payment methods can I use to transfer funds?',
                        content: 'Pesabase sends money through a blockchain-based platform, allowing users to transfer funds to South Sudan almost instantly via the phone app, website app, or agents.',
                  },
                  {
                        title: 'How long will it take to pick up the funds once transferred?',
                        content: 'Funds will be received by the end party in a matter of seconds, always in less than one minute. Unlike traditional banking systems that can take up to days or MTOs that take at least a few hours, money transfers to and within South Sudan are settled in seconds when using Pesabase.',
                  },
                  {
                        title: 'Why is identity verification required?',
                        content: 'Identification is necessary when using Pesabase to comply with "Know Your Customer" (KYC) regulations. These regulations are designed to prevent financial crimes such as money laundering, identity theft, and fraud by ensuring that financial institutions properly verify their customers identities before granting access to services. This process involves collecting and validating personal information, such as government-issued IDs and addresses. Please remember that you can perform one “send” transaction before having to provide your KYC details.'
                  }
            ],
      }
];
const FaqTabs = () => {
      const [activeTab, setActiveTab] = useState(0);

      const handleTabChange = (event, newValue) => {
            setActiveTab(newValue);
      };
      useBodyClass('p-faq');
      // getting faq data from api
      // const { data } = useSWR('vacancys', () => getVacancy());
      // console.log(data);
      return (
            <main>
                  <Header />
                  <section className='s-hero'>
                        <Container disableGutters>
                              <div className='s-hero-top'>
                                    <div className='s-hero-top-wrap'>
                                          <div className='s-hero-top-tabs'>
                                                <div className='s-hero-top-title'>
                                                      <h1>Faq</h1>
                                                </div>
                                                <AppBar position='static'>
                                                      <Tabs
                                                            orientation='vertical'
                                                            value={activeTab}
                                                            onChange={handleTabChange}
                                                            TabIndicatorProps={{
                                                                  children: <MyIndicatorIcon />,
                                                            }}
                                                      >
                                                            {faqs.map((category, index) => (
                                                                  <Tab key={uuidv4()} label={category.category} index={index} />
                                                            ))}
                                                      </Tabs>
                                                </AppBar>
                                          </div>
                                          <div className='s-hero-top-accordion'>
                                                {faqs.map((category, index) => (
                                                      <div key={uuidv4()}>
                                                            {activeTab === index &&
                                                                  category.faqList.map((faq, faqIndex) => (
                                                                        <AccordionFaq key={uuidv4()} faq={{ ...faq, title: <Typography variant="h2" style={{ fontSize: '1.5rem' }}>{faq.title}</Typography>, content: <div dangerouslySetInnerHTML={{ __html: faq.content.replace(/<\/li><li>/g, '</li><br/><li>') }} /> }} faqIndex={faqIndex} />
                                                                  ))}
                                                      </div>
                                                ))}
                                          </div>
                                    </div>
                              </div>
                        </Container>
                  </section>
                  <Footer />
            </main>
      );
};

export default FaqTabs;



// [
//       {
//         "relation": [
//           "delegate_permission/common.handle_all_urls",
//           "delegate_permission/common.get_login_creds"
//         ],
//         "target": {
//           "namespace": "android_app",
//           "package_name": "com.pesabase.app",
//           "sha256_cert_fingerprints": [
//             "05:5A:D1:5E:78:0C:CC:3D:BE:76:E3:43:51:9F:59:70:B1:C2:06:9B:07:11:87:98:C2:CC:FC:60:FB:C2:83:6F"
//           ]
//         }
//       }
//     ]